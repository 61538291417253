import { render, staticRenderFns } from "./free_lesson.vue?vue&type=template&id=13456b41&scoped=true"
import script from "./free_lesson.vue?vue&type=script&lang=js"
export * from "./free_lesson.vue?vue&type=script&lang=js"
import style0 from "./free_lesson.vue?vue&type=style&index=0&id=13456b41&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13456b41",
  null
  
)

export default component.exports