<template>
  <div class="allPage">
    <heads :more="showMore" :msg="title_msg"></heads>
    <van-list v-show="isShow" v-model="loading" :finished="finished" finished-text="没有更多了" @load="getMyBuyLesson">
      <div v-for="(lesson,index) in list" :key="index" class="main" @click="go_url(lesson)">
        <datalists
          :type="2"
          :list-type="1"
          :is-zkb="isZkb"
          :thumb="lesson.thumb"
          :atitle="lesson.lesson_name"
          :date="lesson.end_time"
          :is-end="lesson.is_end"
          :lesson-looked="lesson.looked_lesson_long"
          :lesson-long="lesson.lesson_long"
          :show-reg-state="lesson.show_reg_state"
          :is-add-class="lesson.is_add_class"
          :lesson_type="lesson.type"
          :is-invoice="lesson.is_invoice"
          :invoice-status="lesson.invoice_status"
          @writeRecord="go_registration_form(lesson.student_url)"
        ></datalists>
      </div>
    </van-list>
    <div v-show="!isShow" class="noDatas">
      <img src="@/static/images/my_index/kongzt.png" alt>
      <p>您还没有免费课程哦~</p>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
import heads from '@/compontens/heads.vue'
import { myFreeLesson } from '@/api/my_user.js'
import datalists from '@/compontens/datalist'
import tool from '@/utils/tools'

export default {
  components: { heads, datalists },
  data() {
    return {
      isLBKK: false,
      title_msg: '免费课程',
      lesson_name: '',
      list: [],
      isshow: false,
      showMore: true,
      page: 1,
      limit: 10,
      loading: false,
      finished: false,
      isShow: true,
      isZkb: false,
      agency_id: ''
    }
  },
  mounted() {
    sessionStorage.setItem('edi_address_id', '')
  },
  created() {
    this.agency_id = Cookies.get('agency_id')
    const isZk = location.host.indexOf('zk')
    if (this.agency_id == 11 || isZk !== -1) {
      this.isZkb = true
    } else {
      this.isZkb = false
    }
    const herf = window.location.href
    if (herf.indexOf('islbkk=true') != -1) {
      this.isLBKK = true
    } else {
      this.isLBKK = false
    }
    console.log(this.agency_id + ' ' + this.isZkb + ' ' + this.isLBKK + '-----------------')
  },
  methods: {
    go_url(lesson) {
      if (this.isLBKK) {
        window.location.href = 'https://m.zk468.com/lbkk/study?lesson_id=' + lesson.lesson_id
      } else if (this.isZkb) {
        if (tool.isInApp()) {
          const str = 'index=0' + '&lesson_id=' + lesson.lesson_id + '&lesson_name=' + lesson.lesson_name
          if (tool.isInIosApp()) {
            window.webkit.messageHandlers.vue_openTabbar.postMessage(encodeURIComponent(str))
          } else {
            this.$jsbridge.callHandler(
              'vue_openTabbar'
              , str
              , function(responseData) {
                // android 返参数接受
              }
            )
          }
        } else {
          // 注考帮h5
          const url = 'https://m.zk468.com/'
          Cookies.set('lesson_id', lesson.lesson_id, { expires: 365 })
          Cookies.set('lesson_name', lesson.lesson_name, { expires: 365 })
          window.location.href = url
        }
      } else {
        window.location.href = lesson.lesson_url + '#i=2'
      }
    },
    go_registration_form(url) {
      window.location.href = url
    },
    // 已购课程列表
    getMyBuyLesson() {
      const p = {
        page: this.page,
        limit: this.limit
      }
      myFreeLesson(p).then(res => {
        this.loading = false
        const result = res.result ? res.result : {}
        const arr = result.list ? result.list : []
        if (arr.length < this.limit) {
          // 么有了
          this.finished = true
        } else {
          // 还有
          this.finished = false
          this.page++
        }
        this.list = this.list.concat(arr)
        if (this.list.length > 0) {
          this.isShow = true
        } else {
          this.isShow = false
        }
      }).catch(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.noDatas {
  margin-top: 50%;
  text-align: center;
  img {
    margin: 0 auto;
    width: 250px;
    height: 250px;
    display: block;
  }
  p {
    font-family: PingFangSC-Regular;
    margin-top: 20px;
    font-size: 24px;
    color: #333;
  }
}
.img_h {
  width: 40px;
  height: 40px;
  position: absolute;
  right: 30px;
  top: 44px;
  z-index: 1;
}
.imgs {
  width: 251px;
  height: 155px;
  margin-left: 30px;
}
.dl {
  width: 100%;
  height: 210px;
  margin: 20px auto;
}
.dl dt {
  float: left;
}
.dl dd {
  float: right;
  // width: 420px;
  width: calc(100% - 310px);
}
.dls {
  border-bottom: #ececec 1px solid;
  position: relative;
}
.ke_name {
  font-size: 30px;
  word-break: break-all;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 30px;
}
.ke_yxq {
  color: #9d9ca1;
  line-height: 40px;
}
.ke_yxx {
  color: #d21b2e;
}
.fill_in {
  font-family: PingFangSC-Regular, PingFang SC;
  width: 213px;
  height: 50px;
  border-radius: 25px;
  background-color: #ee2e2e;
  border: 1px solid #ee2e2e;
  color: #fff;
  text-align: center;
  line-height: 54px;
  font-size: 24px;
  float: right;
  position: absolute;
  right: 25px;
  bottom: 20px;
}

.allPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #f5f5f5;
}
</style>
